<template>
  <div class="b-card-products">
    <v-btn fab class="b-card-products__btn">
      <v-icon class="b-card-products__btn--icon">
        {{ icon }}
      </v-icon>
    </v-btn>

    <h3 class="b-card-products__title">
      {{ title }}
    </h3>

    <div class="b-card-products__info">
      <span class="b-card-products__info--life" v-if="amountLife">
        {{ amountLife }} {{ amountLife > 1 ? 'vidas' : 'vida' }}
      </span>

      <span class="b-card-products__info--total" v-if="subtotal">
        <strong>Subtotal:</strong>
        {{ formatMoney(subtotal) }}
      </span>
    </div>

    <BaseButton
      outlined
      color="primary"
      title="Ver detalhes"
      height="35"
      v-if="disabled"
      @click="detail"
    />

    <DialogBilling
      v-if="dialog"
      :dialog="dialog"
      :employeeId="employeeId"
      :productType="productType"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { formatMoney } from '@/helpers/formatting';
import DialogBilling from '@/components/pages/movements/dialogs/dialog-billing';

export default {
  components: {
    DialogBilling
  },

  props: {
    amountLife: {
      type: Number,
      require: true
    },
    subtotal: {
      type: Number,
      require: true
    },
    productType: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    title: '',
    icon: '',
    dialog: false
  }),

  mounted() {
    switch (this.productType) {
      case 'Assistência médica':
        this.icon = 'fa-solid fa-stethoscope';
        this.title = 'Saúde';
        break;
      case 'Assistência odontológica':
        this.icon = 'fa-solid fa-tooth';
        this.title = 'Odonto';
        break;
      case 'Seguro de vida':
        this.icon = 'fa-solid fa-heart-pulse';
        this.title = 'Vida';
        break;

      default:
        break;
    }
  },

  methods: {
    formatMoney,

    detail() {
      this.dialog = true;
    },

    closeModal() {
      this.dialog = false;
    }
  }
};
</script>
