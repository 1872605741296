<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="900"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-form
      @submit.prevent="estimate"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ productType }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                label="Data de início"
                required
                :rules="[required]"
                v-model="model.billingStartDate"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                label="Data de envio"
                required
                :rules="[required]"
                v-model="model.billingSendDate"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                label="Data de conclusão"
                required
                :rules="[required]"
                v-model="model.billingEndDate"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseNumber
                label="Dia de vencimento"
                maxlength="2"
                disabled
                required
                :rules="[required]"
                v-model="model.expiryDay"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseNumber
                label="Dia de corte"
                maxlength="2"
                disabled
                required
                :rules="[required]"
                v-model="model.cutOffDay"
              />
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="6"
              v-if="model.canEditBilling"
            >
              <BaseButton
                type="submit"
                color="secondary"
                :disabled="!valid"
                title="Calcular"
                height="40"
              />
            </v-col>
          </v-row>
          <v-row dense v-if="model.items.length > 0">
            <v-col cols="12">
              <ReportTable :items="model.items" :total="model.total" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="b-dialog--actions" v-if="model.canEditBilling">
          <BaseButton color="primary" title="Salvar" @click="save" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    ReportTable: () => import('../table/report-billing')
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      billingStartDate: null,
      billingSendDate: null,
      billingEndDate: null,
      cutOffDay: 0,
      expiryDay: 0,
      total: 0,
      items: [],
      canEditBilling: false
    }
  }),

  created() {
    this.search();
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },

    async save() {
      try {
        const { billingStartDate, billingSendDate, billingEndDate } =
          this.model;

        const params = {
          employeeId: this.employeeId,
          productType: this.productType,
          billingStartDate,
          billingSendDate,
          billingEndDate
        };

        const movementService = new MovementService();
        const { status } = await movementService.putEmployeeBilling(params);

        if (status === 200 || status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    estimate() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = {
        startDate: this.model.billingStartDate.split('T')[0],
        conclusionDate: this.model.billingEndDate.split('T')[0],
        sendDate: this.model.billingSendDate.split('T')[0]
      };

      this.search(params);
    },

    async search(filter) {
      let params = {
        employeeId: this.employeeId,
        productType: this.productType
      };

      if (filter) {
        params = { ...params, ...filter };
      }

      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getEmployeeBilling(
          params
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
